module.exports = ['$scope', '$rootScope', 'settings', '$location', '$translate', 'Survey',
  function ($scope, $rootScope, settings, $location, $translate, Survey) { //eslint-disable-line

    'use strict';

    $scope.stepNumber = 5;
    $scope.$emit('wellbe_wizard_step', { stepNumber: $scope.stepNumber });

    $scope.headerTitle = 'Uitnodiging';
    $scope.headerDescription = 'Nodig je deelnemers uit met een persoonlijk bericht. Je kan het bericht aanpassen. Gebruik <span class="bold">{{link}}</span> om in je bericht de link naar de bevraging in te voegen.';

    const linkVar = '{{link}}';
    const usernameVar = '{{username}}';

    let defaultSubject = 'Bevraging welzijn op het werk: WellBe.Vlaanderen';

    let defaultBody = 'Beste, </p>'
    + 'WellBe.Vlaanderen bevat een vragenlijst. Elke vraag moet beantwoord worden. \n\n </p>'
    + 'Links op het scherm wordt je gevraagd in welke mate de geschetste situatie nu op jou van toepassing is. '
    + 'Rechts op het scherm wordt je gevraagd of er actie moet ondernomen worden om de situatie te verbeteren. '
    + 'De mogelijkheid van antwoorden gaan van helemaal niet akkoord tot helemaal akkoord. Wanneer de vraag op jou niet van toepassing is of je weet het antwoord niet, klik dan op ? \n\n </p>'
    + 'Het invullen duurt ongeveer 15 minuten. \n\n </p>'
    + 'De resultaten zullen nooit herleid kunnen worden naar individuele medewerkers. Anonimiteit is verzekerd! </p>'
    + 'Je kunt de bevraging hier invullen: ' + linkVar + ' </p>'
    + 'Let op: stuur deze mail met link niet door naar een ander e-mailadres om de juistheid van de resultaten te garanderen.\n\n</p>'
    + 'Met vriendelijke groeten, \n\n </p>'
    + usernameVar + ' \n <br>'
    + 'In opdracht van het bestuur';

    Survey.getSurvey().then(survey => {
      // load email template data
      if (!survey.emailtemplate) {
        survey.emailtemplate = {
          subject: defaultSubject,
          body: defaultBody
        };
      }

      $scope.survey = survey;
    });

    $scope.onNext = () => {
      if ($scope.survey.emailtemplate.subject && $scope.survey.emailtemplate.body
          && $scope.survey.emailtemplate.body.indexOf(linkVar) !== -1
          && $scope.survey.emailtemplate.body.indexOf(usernameVar) !== -1) {
        Survey.saveSurvey($scope.survey).then(() => {
          $location.path('/overview');
        });
      } else {
        let errorMessages = [];
        if (!$scope.survey.emailtemplate.subject) {
          errorMessages.push('Missing email subject. (TODO translate)');
        }
        if ($scope.survey.emailtemplate.body.indexOf(linkVar) === -1) {
          errorMessages.push('De link ontbreekt. Wil je de volgende tekst aan de uitnodiging toevoegen : Je kan de bevraging hier invullen: {{link}}');
        }
        if ($scope.survey.emailtemplate.body.indexOf(usernameVar) === -1) {
          errorMessages.push(`Je naam ontbreekt. Wil je ${'{{username}}'} toevoegen na de slotformule ?`);
        }

        $scope.feedbackMessage = {
          text: errorMessages.join('. ')
        };
      }
    };

    $scope.back = () => {
      $location.path('/survey-selection');
    };

    $scope.summernoteOptions = {
      height: 170,
      toolbar: [
        ['style', ['bold', 'italic', 'clear']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['link', ['linkDialogShow']]
      ]
    };
  }];
